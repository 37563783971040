import React from "react";
import Input from "../components/Input/Input";
import TextArea from "../components/TextArea/TextArea";
import Button from "../components/Button/Button";

const Demo = () => {
  return (
    <div>
      <Input />
      <br />
      <TextArea />
      <br />
      <Button label={'View Portfolio'} />
    </div>
  );
};

export default Demo;
