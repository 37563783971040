import React from "react";
import classes from "./TextArea.module.css";

export default function TextArea({
  value,
  setter,
  label,
  placeholder,
  customStyle,
  labelStyle,
  rows,
  className,
  name,
  ...props
}) {
  return (
    <div className={classes.textAreaBox}>
      {label && <label style={{ ...labelStyle }}>{label}</label>}
      <textarea
        className={className}
        rows={rows}
        placeholder={placeholder}
        value={value}
        style={{ ...customStyle }}
        onChange={(e) => {
          setter && setter(e.target.value);
        }}
        name={name}
        {...props}
      />
    </div>
  );
}
