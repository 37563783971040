import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Navbar/Header";
import classes from "./PortfolioPage.module.css";

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Footer from "../../components/Footer/Footer";
import { BaseURL, imageUrl, recordsLimit } from "../../config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import Loader from "../../components/Loader";
import { decideAspectRadio } from "../../helper/decideAspectRatio";
import Button from "../../components/Button/Button";
import MultiCarousel from "react-multi-carousel";

const PortfolioPage = () => {
  const [portfolioImages, setportfolioImages] = useState([]);
  const [portfolioHeading, setportfolioHeading] = useState(null);
  const [portfolioPageNo, setPortfolioPageNo] = useState(2);
  const [portfoliototalPages, setPortfolioTotalPages] = useState(1);

  const [dailyWorkImages, setDailyWorkImages] = useState([]);
  const [dailyWorkHeading, setDailyWorkHeading] = useState(null);
  const [dailyWorkTotalCount, setDailyWorkTotalCount] = useState(null);
  const [dailyWorkPageNo, setDailyWorkPageNo] = useState(2);

  const [testImage, setTestImage] = useState([]);

  // const [portfolioData, setportfolioData] = useState();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  async function getAllData() {
    setLoading(true);
    const portfolioUrl = Get(
      BaseURL(`portfolio?page=${1}&limit=${recordsLimit}`)
    );
    const dailyWorkUrl = Get(BaseURL(`daily-work?page=${1}&limit=${4}`));
    const [portfolioRes, dailyWorkRes] = await Promise.all([
      portfolioUrl,
      dailyWorkUrl,
    ]);
    if (portfolioRes && dailyWorkRes) {
      setImages(portfolioRes?.data?.data?.images);
      setportfolioHeading(portfolioRes?.data?.data?.heading?.heading);
      setDailyWorkImages(dailyWorkRes?.data?.data?.images);
      setDailyWorkHeading(dailyWorkRes?.data?.data?.heading?.heading);
      setPortfolioTotalPages(portfolioRes?.data?.totalCount);
      setDailyWorkTotalCount(dailyWorkRes?.data?.totalCount);
    }
    setLoading(false);
  }

  async function getDailyWork() {
    const url = BaseURL(`daily-work?page=${dailyWorkPageNo}&limit=${4}`);
    setIsLoadingMore(true);
    const response = await Get(url);
    if (response !== undefined) {
      const resData = response?.data;
      setDailyWorkImages((p) => [...p, ...response?.data?.data?.images]);
      setDailyWorkPageNo((p) => p + 1);
    }
    setIsLoadingMore(false);
  }
  async function getPortfolio() {
    const url = BaseURL(
      `portfolio?page=${portfolioPageNo}&limit=${recordsLimit}`
    );
    setIsLoadingMore(true);
    const response = await Get(url);
    if (response !== undefined) {
      const resData = response?.data;
      setImages(resData?.data?.images);
      setportfolioHeading(resData?.data?.heading?.heading);
      setPortfolioPageNo((p) => p + 1);
      setPortfolioTotalPages(resData?.data?.totalCount);
    }
    setIsLoadingMore(false);
  }

  // set Images with aspect ratio
  function setImages(imgs) {
    const tempArray = imgs?.map((item) => {
      return {
        src: imageUrl(item?.imageKey),
        // width: Math.floor(Math.random() * 5) + 3,
        // height: Math.floor(Math.random() * 5) + 3,
        width: item?.width == 0 ? 5 : decideAspectRadio(item?.width),
        height: item?.height == 0 ? 5 : decideAspectRadio(item?.height),
      };
    });
    setportfolioImages((p) => [...p, ...tempArray]);
    // setportfolioImages(tempArray);
  }

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <Header />
          {/* <Container className={classes.portfolio_container}>
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <Col md={12}>
                <h1 className={classes.mar_top_title}>{dailyWorkHeading}</h1>
              </Col>
              {dailyWorkImages?.map((item, i) => {
                return (
                  <div className={classes?.dailyWorkImagesConti}>
                    <div className="img_loader_main">
                      <img
                        src={imageUrl(item?.imageKey)}
                        className="img-fluid mb-3 "
                        onLoad={(e) => {
                          setTestImage((p) => [...p, i]);
                        }}
                      />
                      {!testImage.includes(i) && (
                        <div className="img_loader"></div>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className={classes?.loadMorecontainer}>
                {isLoadingMore ? (
                  <div className="simple-loader" />
                ) : (
                  <div>
                    {dailyWorkImages?.length < dailyWorkTotalCount && (
                      <Button
                        label={"Load More"}
                        onClick={() => getDailyWork()}
                      />
                    )}
                  </div>
                )}
              </div>
            </Row>
          </Container> */}
          <Container className={`${classes.portfolio_container} mb-5`}>
            <Row>
              <Col>
                <h1 className="text-center mb-4 ">{portfolioHeading}</h1>

                <div className={classes.portfolio_grid}>
                  <Gallery
                    photos={portfolioImages}
                    onClick={openLightbox}
                    direction={"column"}
                    margin={4}
                  />

                  <ModalGateway>
                    {viewerIsOpen ? (
                      <Modal onClose={closeLightbox}>
                        <Carousel
                          currentIndex={currentImage}
                          views={portfolioImages.map((x) => ({
                            ...x,
                            // srcset: x.srcSet,
                            // caption: x.title,
                          }))}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>

                  <div className={classes?.loadMorecontainer}>
                    {isLoadingMore ? (
                      <div className="simple-loader" />
                    ) : (
                      <div>
                        {portfolioImages?.length <= portfoliototalPages && (
                          <Button
                            label={"Load More"}
                            onClick={() => getPortfolio()}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PortfolioPage;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1921 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1920, min: 1441 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1440, min: 1025 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 1024, min: 769 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};
