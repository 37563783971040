import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import classes from "./HeroSection.module.css";

function HeroSection({ resData }) {
  const navigate = useNavigate();

  return (
    <section className={`scroll-area ${classes?.mainSection}`}>
      <Container className={`${classes.hero_cus_b} ${classes?.inner}`}>
        <h1>{resData?.pages?.homeSection1Heading}</h1>
        <Button
          label={"View Portfolio"}
          onClick={() => navigate("/portfolio")}
        />
      </Container>
    </section>
  );
}

export default HeroSection;
