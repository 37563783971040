import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { imageUrl } from "../../../config/apiUrl";
import { sec1Img } from "../../../constant/imagePath";

import classes from "./IntroSection.module.css";

function IntroSection({ resData }) {
  return (
    <section className={`scroll-area ${classes?.main}`}>
      <Container fluid className={classes?.container}>
        <Row className={classes.cus_row_intro}>
          <Col md={5}>
            <div className={classes?.info}>
              <p className={classes?.lighterTxt}>
                {resData?.pages?.homeSection2MiniHeading}
              </p>
              <h1>{resData?.pages?.homeSection2Heading}</h1>
              <p className={classes?.para}>
                {resData?.pages?.homeSection2Description}
              </p>
            </div>
          </Col>
          <Col md={7}>
            <div className={classes?.imageContainer}>
              <img
                src={imageUrl(resData?.pages?.homeSection2CoverImage)}
                className="img-fluid"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default IntroSection;

const para = `Vi är ett familjeföretag i andra generation som tillverkat inredning med fokus på högsta kvalite sedan 1975.\nVarje projekt tillverkas helt efter kundens önskemål gällande material och design.\nMed erfarenhet från tusentals tillverkade snickerier kan vi förverkliga era inredningsdrömmar.`;
