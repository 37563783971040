import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import Footer from "../../components/Footer/Footer";
import HeroSection from "../../components/HeroSection/HeroSection";
import IntroSection from "../../components/HeroSection/IntroSection/IntroSection";
import Loader from "../../components/Loader";
import Header from "../../components/Navbar/Header";
import OurIntro from "../../components/OurIntro/OurIntro";
import { BaseURL, imageUrl } from "../../config/apiUrl";
import { sec2Img, sec3Img, sec4Img } from "../../constant/imagePath";
import createScrollSnap from "scroll-snap";

import classes from "./LandingPage.module.css";
import { useMediaQuery } from "@mui/material";

function LandingPage() {
  const [resData, setResData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const refernce_b = React.createRef();
  const matches = useMediaQuery('(min-width:1024px)');
  const bindScrollSnap = () => {
    const element = refernce_b?.current;
    refernce_b?.current !== null && createScrollSnap(
      element,
      {
        snapDestinationY: "100%",
        threshold:-2,
        timeout: 100,
        duration: 300,
      },
      () => console.log("snapped")
    );
  };
  useEffect(() => {
    matches &&  bindScrollSnap();
  }, [refernce_b]);


  // getCmsData
  const getCmsData = async () => {
    const apiUrl = BaseURL(`cms/pages/all?pages=home`);
    setIsLoading(true);
    const response = await Get(apiUrl);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    if (response !== undefined) {
      setResData(response?.data?.data);
    }
  };
  useEffect(() => {
    getCmsData();
  }, []);

  return (
    <>
      <div id="refernce_b" ref={refernce_b}>
      <Header />
        <div className="page first-page">
          <HeroSection resData={resData} />
        </div>
        <div className="page second-page">
          <IntroSection resData={resData} />
        </div>
        <div className="page third-page">
          <OurIntro
            lightText={resData?.pages?.homeSection3MiniHeading}
            heading={resData?.pages?.homeSection3Heading}
            image={imageUrl(resData?.pages?.homeSection3CoverImage)}
            para={resData?.pages?.homeSection3Description}
          />
        </div>
        <div className="page fourth-page">

        <OurIntro
          lightText={resData?.pages?.homeSection4MiniHeading}
          heading={resData?.pages?.homeSection4Heading}
          image={imageUrl(resData?.pages?.homeSection4CoverImage)}
          para={resData?.pages?.homeSection4Description}
          right
        />
        </div>
        <div className="page fifth-page">

        <OurIntro
          lightText={resData?.pages?.homeSection5MiniHeading}
          heading={resData?.pages?.homeSection5Heading}
          image={imageUrl(resData?.pages?.homeSection5CoverImage)}
          para={resData?.pages?.homeSection5Description}
        />
        </div>
        <div className="page sixth-page">

        <Footer />
        </div>

      </div>
    </>
  );
}

export default LandingPage;

const p2 = `Vi hjälper er från första ide till färdigt resultat. Vi designar, tillhandahåller ritningar, tillverkar och monterar.\nGenom våra samarbetspartners levererar vi allt till ert projekt. Stenskivor, metallarbeten, vitvaror, blandare mm.`;
