import React, { useState } from "react";
import classes from "./input.module.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

/**
 * Primary UI component for user interaction
 */
const Input = ({
  type,
  label,
  value,
  setter,
  placeholder,
  disabled,
  customStyle,
  inputStyle,
  labelStyle,
  leftIcon,
  inputRef,
  inputContainer,
  name,
  regexType,
  ...props
}) => {
  const [passToggle, setPassToggle] = useState(false);
  let inputContainerStyleObject = Object.assign(
    {},
    leftIcon && { paddingLeft: "50px" }
  );
  return (
    <>
      <div className={`${[classes.Container].join(" ")}`}>
        {label && (
          <label
            htmlFor={`input${label}`}
            className={`mb-2  ${[
              classes.labelText,
              disabled && classes.disabled,
            ].join(" ")}`}
            style={{ ...labelStyle }}
          >
            {label}
          </label>
        )}
        <div
          className={[
            classes.inputPassContainer,
            inputContainer && inputContainer,
          ].join(" ")}
          style={{ ...customStyle }}
        >
          {leftIcon && (
            <div style={{ color: "#D1CCEB" }} className={classes.leftIconBox}>
              {leftIcon}
            </div>
          )}
          <input
            autocomplete="new-password"
            value={value}
            onChange={(e) => {
              if (setter) {
                if (regexType === "number") {
                  setter(e.target.value.replace(/[0-9]/g, ""));
                } else if (regexType === "character") {
                  setter(e.target.value.replace(/[^\d]/g, ""));
                } else {
                  setter(e.target.value);
                }
              }
            }}
            disabled={disabled}
            placeholder={placeholder}
            type={passToggle == true ? "text" : type}
            id={`input${label}`}
            className={` ${[classes.inputBox].join(" ")}`}
            style={{ ...inputContainerStyleObject, ...inputStyle }}
            ref={inputRef}
            {...props}
            name={name}
          />
          {type == "password" && passToggle == false && (
            <AiFillEyeInvisible
              className={classes.passwordIcon}
              onClick={(e) => setPassToggle(!passToggle)}
            />
          )}
          {type == "password" && passToggle && (
            <AiFillEye
              className={classes.passwordIcon}
              onClick={(e) => setPassToggle(!passToggle)}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default Input;
