import { RouterProvider } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { router } from "./assets/routes/routes";
import "react-multi-carousel/lib/styles.css";

function App() {
  return (
    <>
      <ToastContainer />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
