import React from "react";
import { useRouteError } from "react-router-dom";
import { eightImg } from "../../constant/imagePath";
import Button from "../Button/Button";

import classes from "./ErrorElement.module.css";

const ErrorElement = ({
  msg = "Brace yourself till we get the error fixed. You may also refresh the page or try again later.",
}) => {
  const routeError = useRouteError();

  return (
    <div className={classes?.container}>
      <div className={classes?.main}>
        <div className={classes?.imageContainer}>
          <img src={eightImg} />
        </div>
        <h1>Oops!</h1>
        <h3>Something went wrong</h3>
        {/* <p>{routeError?.message || msg}</p>
        <p>{routeError?.stack || msg}</p> */}
        <p>{msg}</p>
        <Button
          label={"Reload"}
          primary
          onClick={() => window.location.reload()}
          className={classes?.customButton}
        />
      </div>
    </div>
  );
};

export default ErrorElement;
