import React from "react";
import classes from "./button.module.css";
import { ImArrowRight2 } from 'react-icons/im'

export default function Button({
  label,
  customStyle,
  onClick,
  disabled,
  children,
  className,
  leftIcon,
  primary = false,
  isRightArrow = false,
  type,
  ...props
}) {
  let styleObject = Object.assign({}, customStyle);
  

  return (
    <>
      <button
      type={type && type}
        className={`${[classes.btn, primary ? classes.blue : classes.lightOrange, className && className].join(" ")}`}
        style={{
          ...styleObject,
        }}
        onClick={onClick}
        disabled={disabled ? disabled : false}
        {...props}
      >
        {leftIcon && leftIcon}
        {label && label}
        {children && children}
        {isRightArrow && <ImArrowRight2 className={classes.rightArrow} />}
      </button>
    </>
  );
};
