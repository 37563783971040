
export function decideAspectRadio(val) {

    const array = [
        { value: 1000, ratio: 10 },
        { value: 100, ratio: 1 },
    ]
    if (val > array[0]?.value) {
        return array[0]?.ratio
    } else if (val < array[1]?.value) {
        return array[1]?.ratio
    } else {
        return Number(String(val)?.charAt(0))
    }

}