import { createBrowserRouter } from "react-router-dom";
import ErrorElement from "../../components/ErrorElement/ErrorElement";

// pages
import Demo from "../../pages/Demo";
import LandingPage from "../../pages/LandingPage/LandingPage";
import PortfolioPage from "../../pages/PortfolioPage/PortfolioPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorElement />,
  },
  {
    path: "/portfolio",
    element: <PortfolioPage />,
    errorElement: <ErrorElement />,
  },
  // {
  //   path: "/dodo",
  //   element: <Demo />,
  // },
]);

export { router };
