import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import { logo } from "../../constant/imagePath";

import classes from "./Header.module.css";

function Header() {
  const location = useLocation();
  return (
    <Navbar className={classes?.navbar}>
      <Container>
        <Link to="/portfolio">Portfolio</Link>
        <Link to="/" className={classes?.brandContainer}>
          <img style={{
            background:"unset"
          }} src={logo} className="d-inline-block align-top" alt="logo" />
        </Link>
        <a href="#contact">Kontakt</a>
        <Navbar.Toggle />
      </Container>
    </Navbar>
  );
}

export default Header;
