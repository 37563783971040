import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SocialIcon } from "react-social-icons";
import { toast } from "react-toastify";
import { Get, Post } from "../../Axios/AxiosFunctions";
import {
  apiHeader,
  BaseURL,
  imageUrl,
  toastConfig,
  validateEmail,
} from "../../config/apiUrl";

import { logo } from "../../constant/imagePath";
import Button from "../Button/Button";
import Input from "../Input/Input";
import TextArea from "../TextArea/TextArea";
import classes from "./Footer.module.css";

function Footer() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [footerData, setFooterData] = useState(null);

  // getCmsData
  const getCmsData = async () => {
    const apiUrl = BaseURL(`cms/pages/all?pages=footer`);
    const response = await Get(apiUrl);

    if (response !== undefined) {
      setFooterData(response?.data?.data?.pages);
    }
  };
  useEffect(() => {
    getCmsData();
  }, []);

  //  handleSubmit
  const handleSubmit = async (e) => {
    e?.preventDefault();

    if (isApiCalling) return;

    const params = {
      name,
      email,
      message,
    };

    for (let key in params)
      if (params[key].trim() === "")
        return toast.warn(`${key} can not be empty.`, toastConfig);

    if (!validateEmail(email))
      return toast.warn("Please enter a valid email address.", toastConfig);

    const url = BaseURL("contact-us");
    setIsApiCalling(true);
    const response = await Post(url, params, apiHeader());
    setIsApiCalling(false);

    if (response !== undefined) {
      toast.success(
        "Thankyou for contacting us. Nisseby team will reach you shortly.",
        toastConfig
      );

      setName("");
      setMessage("");
      setEmail("");
    }
  };

  return (
    <footer
      className={classes?.footer}
      id="contact"
      style={{
        backgroundImage: `url(${imageUrl(footerData?.footerImage)})`,
      }}
    >
      <Container className={classes?.container}>
        <Row>
          <Col md={4}>
            {/* logo */}
            <div className={classes?.logoContainer}>
              <img 
              style={{
                background:"unset"
              }}
              
              src={logo} />
            </div>
            {/* info */}
            <div className={classes?.info}>
              <p>
                <a href={`mailto:${footerData?.email}`}>{footerData?.email}</a>
              </p>
              <p>{footerData?.address}</p>
              <p>
                <a href={`tel:${footerData?.phoneNo}`}>{footerData?.phoneNo}</a>
              </p>
            </div>
            <div className={classes?.socialContainer}>
              {footerData?.links?.map((e, i) => (
                <SocialIcon
                  key={i}
                  url={e?.link}
                  bgColor="var(--light-text)"
                  target={"_blank"}
                />
              ))}
            </div>
          </Col>
          <Col md={8}>
            <form className={classes?.form} onSubmit={handleSubmit}>
              <p className={classes?.lighterText}>KONTAKTA OSS</p>
              <h2>{footerData?.description}</h2>
              <Input
                placeholder={"Name"}
                value={name}
                setter={setName}
                customStyle={{ marginBottom: "20px" }}
              />
              <Input
                placeholder={"Email"}
                value={email}
                setter={setEmail}
                type="email"
                customStyle={{ marginBottom: "20px" }}
              />
              <TextArea
                placeholder={"Message"}
                rows={5}
                value={message}
                setter={setMessage}
                customStyle={{ marginBottom: "20px" }}
              />
              <Button
                label={isApiCalling ? "Please Wait..." : "Skicka"}
                type="submit"
                disabled={isApiCalling}
              />
            </form>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;

const info = {
  email: "info@demotestingserver.com",
  address: "Uppgårdsvägen 1, 179 61 Stenhamra",
  contact: "+46 (0)8 560 44 820",
};

const socialIcons = ["https://www.facebook.com/", "https://www.instagram.com/"];
