import { toast } from "react-toastify";
// //
// export const apiUrl = "https://nisseby-backend.herokuapp.com";
export const apiUrl = "https://nisseby-be.herokuapp.com";
// export const apiUrl ="https://1bd1-103-244-178-85.sa.ngrok.io";
export const socketURL = apiUrl;
export const imageUrl = (image) => `${apiUrl}/api/images/${image}`;
export const pdfUrl = (pdf) => `${apiUrl}/api/pdf/${pdf}`;

export const BaseURL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const recordsLimit = 10;

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";
export const passwordRegex =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const errorMsg = (key, counter) => {
  if (counter == 1)
    return toast.error(`Please Choose ${key} Greater Greater than 0  `);
  if (counter == 2) return toast.error(`Please fill ${key} `);
  if (counter == 3) return toast.error(`Please choose ${key} At least 1  `);
};

export const fallbackUser = "BlankUserImg";

export const renderSkeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const renderSkeleton8 = [1, 2, 3, 4, 5, 6, 7, 8];
export const renderSkeleton4 = [1, 2, 3, 4];
export const falsyArray = [
  null,
  undefined,
  "",
  0,
  false,
  NaN,
  "null",
  "undefined",
  "false",
  "0",
  "NaN",
];

export const firebaseVapidObject = {
  vapidKey:
    "BLfSR8mqHIPpvWfE57Vs16en_D0yjlTUyM_Lb40_2OsVJJgIy2UFpJ7qlE7J6zhkY9Gqq788219TZc-dpIjxUHY",
};

export const stripePublicKey =
  "pk_test_51LkpB1ELkLI21nXwxbTessqNj9PIVANo4T1h94pQF57vNYzcC0djMbrdb760D3khPcopV0My1Oxhti0YoSh3FOTq00sBeSaCQh";

export const Sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const toastConfig = {
  style: {
    backgroundColor: "#000",
    color: "#fff",
  },
};
