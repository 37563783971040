import React, { useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { sec2Img } from "../../constant/imagePath";
import { gsap } from "gsap";

import classes from "./OurIntro.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
const paragraph = `Vill du ha inredning som är tidsenligt med ditt hem?\n Vi designar såväl sekelskifte som modernt som pressar gränser.`;

function OurIntro({
  lightText,
  heading,
  para = paragraph,
  image = sec2Img,
  right = false,
}) {
  const twitterRef = useRef(null);
  const twitterTween = useRef(null);

  const twitterRef1 = useRef(null);
  const twitterTween1 = useRef(null);
  useEffect(() => {
    twitterTween.current = gsap.to(twitterRef.current, {
      duration: 3,
      transform: "scale(0.9)",
      paused: true,
    });
    twitterTween1.current = gsap.to(twitterRef1.current, {
      duration: 3,
      transform: "scale(1.2)",
      paused: true,
    });
  }, []);

  const onMouseEnterHandler = () => {
    twitterTween.current.play();
    twitterTween1.current.play();
  };
  const onMouseLeaveHandler = () => {
    twitterTween.current.reverse();
    twitterTween1.current.reverse();
  };

  const matches = useMediaQuery("(max-width:768px)");
  console.log(matches, "qweeeeeeee");
  return (
    <section
      ref={twitterRef}
      className={`box ${classes?.main} ${right && classes?.mainRight}`}
      style={{
        backgroundImage: !matches ? `url(${image})` : "unset",
        position: "relative",
      }}
    >
      <div
        style={{ right: right && 0 }}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        className={classes.custom_div}
      ></div>
      <Container fluid className={classes?.containerStyles}>
        <Row className={`${classes?.mainRow} ${right && classes?.rightRow}`}>
          {/* {matches &&  */}
          {/* } */}
          {!matches && <Col lg={12} xl={6}></Col>}
          <Col lg={12} xl={6} className={classes?.zero_col}>
            <div className={classes?.info} ref={twitterRef1}>
              <p className={classes?.lighterTxt}>{lightText}</p>
              <h1>{heading}</h1>
              <p className={classes?.para}>{para}</p>
            </div>
          </Col>
          {matches && (
            <Col lg={12} xl={6} className={classes?.mtContainer}>
              <div className={classes.img_div}>
                <img src={image} />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default OurIntro;
